/* eslint-disable
   @typescript-eslint/no-unsafe-member-access

*/

import { useEffect, useRef, useState } from "react";
import type { AppProps } from "next/app";
import { type AppType } from "next/app";
import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider, useUser } from "@supabase/auth-helpers-react";
import { cookieOptions } from "../utils/cookie-options";
import * as Sentry from "@sentry/nextjs";

import { appWithTranslation } from "next-i18next";
import { i18nConfig } from "../../next-i18next.config.mjs";

import { api } from "../utils/api";

import { missingKeyHandler } from "../utils/i18n-utils";
import "../utils/zod-meta";
import { Toaster } from "@/components/ui/toaster";

import "../styles/globals.css";
import "react-datepicker/dist/react-datepicker.css";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ShortMessage } from "@/components/common/ui/ShortMessage";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ConfigProvider } from "@/components/common/config/config-context";
import { Loading } from "@/components/common/Loading";
import { Button } from "@/components/ui/button";
import { useRouter } from "next/router";

const MyApp: AppType = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [supabaseClient] = useState(() =>
    createPagesBrowserClient({ cookieOptions })
  );
  const user = useUser();
  const userRef = useRef<string | null>(null);

  const logout = async () => {
    console.log(`muly:logout`, {});
    await supabaseClient.auth.signOut();
    void router.replace("/");
  };

  // useEffect(() => {
  //   clarity.init("hqe2wk0ljc");
  // }, []);

  supabaseClient.auth.onAuthStateChange((event, session) => {
    // console.log(`supabaseClient.auth.onAuthStateChange: ${event}`, session);
    const id = session?.user?.id || null;
    if (id !== userRef.current) {
      if (id) {
        console.log(`supabaseClient.auth.onAuthStateChange: identify ${id}`);
        Sentry.setUser({
          id,
          username: session?.user?.user_metadata?.name,
          email: session?.user?.phone || session?.user?.email || "unknown",
        });
      } else {
        Sentry.setUser(null);
      }

      userRef.current = id;
      if (id) {
        console.log(`muly:has id, refetch ${id}`);
        void refetch();
      }
    }
  });

  const { data: userProfile, refetch } = api.customer.getUserProfile.useQuery(
    undefined,
    {
      enabled: !!user,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (user && !userProfile) {
    return <Loading />;
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        console.error(`muly:MyApp:Sentry.ErrorBoundary`, {
          error,
          componentStack,
          resetError,
        });
        return (
          // @ts-ignore
          <ShortMessage title="Error" text={error.message} icon="error">
            <Button
              variant="primary"
              onClick={async () => {
                const shouldLogout =
                  // @ts-ignore
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  error.message.includes("not customer of life insurance") ||
                  // @ts-ignore
                  error.httpStatus === 403 ||
                  // @ts-ignore
                  error.httpStatus === 401;
                resetError();
                if (shouldLogout) {
                  await supabaseClient.auth.signOut();
                }
                router.reload();
                // window.location.href = `/`;
              }}
              className="mx-auto w-fit px-14"
            >
              Reload
            </Button>
          </ShortMessage>
        );
      }}
    >
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <ConfigProvider
          value={{
            userProfile: userProfile || null,
            config: {},
          }}
        >
          <TooltipProvider>
            <Component {...pageProps} />
          </TooltipProvider>
        </ConfigProvider>
        <Toaster />
      </SessionContextProvider>
    </Sentry.ErrorBoundary>
  );
};

const I18nApp = appWithTranslation(MyApp, {
  ...i18nConfig,
  missingKeyHandler,
});
export default api.withTRPC(I18nApp);
