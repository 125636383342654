import React from "react";
import { Loading } from "@/components/common/Loading";
import type { UserProfile } from "@/server/db-types";

interface ConfigContextInterface {
  config: Record<string, any>;
  userProfile: UserProfile | null;
}

export const ConfigContext = React.createContext<ConfigContextInterface>({
  config: {},
  userProfile: null,
});

export const ConfigProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: ConfigContextInterface;
}) => {
  return value ? (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  ) : (
    <Loading />
  );
};

export const useConfigContext = () => {
  const context = React.useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useConfigContext must be used within a ConfigProvider");
  }
  return context;
};
