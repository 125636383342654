import type { ReactNode } from "react";
import { AlertTriangle, Check, HelpCircle, Info, XCircle } from "lucide-react";

interface Props {
  icon?: "warning" | "error" | "info" | "question" | "none";
  title: string;
  text: string;
  children?: ReactNode;
}

export const ShortMessage = ({ title, text, children, icon }: Props) => {
  const iconMap = {
    warning: <AlertTriangle className="h-10 w-10" />,
    error: <XCircle className="h-10 w-10" />,
    info: <Info className="h-10 w-10" />,
    question: <HelpCircle className="h-10 w-10" />,
    check: <Check className="h-10 w-10" />,
    none: null,
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex max-w-sm flex-col gap-2  text-center">
        <div>
          <div className="mx-auto w-fit text-[#1F62F2]">
            {iconMap[icon ?? "check"]}
          </div>
          <h1 className="text-2xl font-bold text-[#1F62F2]">{title}</h1>
        </div>
        <p className="text-lg font-medium">{text}</p>
        {children}
      </div>
    </div>
  );
};
