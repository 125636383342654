import * as React from "react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

const buttonVariants = cva(
  "inline-flex flex justify-center items-center justify-center max-w-[240px] py-7 flex justify-center items-center w-full rounded-md text-sm font-medium transition-colors focus:outline-none active:scale-95 disabled:pointer-events-none disabled:opacity-50 ",
  {
    variants: {
      variant: {
        primary:
          "rounded-lg bg-gradient-to-r from-[rgba(254,5,85,1)] to-[rgba(255,135,0,1)] text-base text-white hover:bg-gradient-to-r hover:to-[#db6c06] hover:from-[#cc0d41]",
        "primary-outline":
          "!py-3 !px-1 rounded-lg border text-black border-[rgba(254,5,85,1)] hover:border-transparent text-slate-900 hover:text-white hover:bg-gradient-to-r hover:to-primary_to hover:from-primary",
        secondary:
          "rounded-lg bg-gradient-to-r from-darkBlue to-lightBlue text-base text-white bg-gradient-to-r from-[#0d5c89] to-[#15cec4] hover:bg-gradient-to-r hover:from-[#0d4867] hover:to-[#158b87]",
        default:
          " color-black cursor-pointer flex justify-center rounded-lg border border-[rgba(254,5,85,1)] focus:outline-none hover:border-transparent hover:bg-gradient-to-r hover:from-[rgba(254,5,85,1)] hover:to-[rgba(255,135,0,1)] active:scale-95",
        "secondary-outline":
          "!py-3 !px-1 rounded-lg border text-black border-secondary hover:border-transparent text-slate-900 hover:text-white hover:bg-gradient-to-r hover:from-secondary hover:to-secondary_to bg-clip-padding",
        destructive:
          "bg-red-500 text-white hover:bg-red-600 dark:hover:bg-red-600",
        alternate:
          "border border-slate-300 bg-transparent rounded-lg text-slate-500 hover:border-slate-700 hover:text-slate-700 dark:border-slate-700 dark:text-slate-100",
        outline:
          "border border-slate-300 bg-transparent rounded-lg text-slate-500 hover:border-slate-700 hover:text-slate-700 dark:border-slate-700 dark:text-slate-100",
        subtle:
          "bg-slate-100 text-slate-900 hover:bg-slate-200 dark:bg-slate-700 dark:text-slate-100",
        ghost:
          "bg-transparent hover:bg-slate-100 data-[state=open]:bg-transparent dark:text-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-100 dark:data-[state=open]:bg-transparent",
        link: "bg-transparent text-slate-900 underline-offset-4 hover:bg-transparent hover:underline dark:bg-transparent dark:text-slate-100 dark:hover:bg-transparent",
      },
      size: {
        default: " py-2 px-5",
        sm: "h-9 rounded-md px-2",
        lg: "h-11 rounded-md px-8",
        rec: "p-3.5 h-10",
        square:
          "mb-2 inline-block w-auto w-[5rem] gap-6 w-full rounded-[1.5rem] border border-transparent bg-[#f0f1f5] p-5 px-8 shadow-lg hover:border-[#C9CAD1] active:border-[#C9CAD1] data-[state=on]:border-[#C9CAD1]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  // TODO:TW need to implement
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, disabled, variant, size, isLoading, children, ...props },
    ref
  ) => {
    //TODO: isLoading
    return (
      <>
        <button
          type="button"
          className={cn(buttonVariants({ variant, size, className }))}
          disabled={disabled || isLoading}
          ref={ref}
          {...props}
        >
          {isLoading ? (
            <div className="relative flex items-center justify-center">
              <Loader2 className="absolute mx-auto my-0	h-4 w-4 animate-spin" />
              <span className="flex opacity-0">{children}</span>
            </div>
          ) : (
            children
          )}
        </button>
      </>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
